var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "canvas",
    {
      ref: "elCanvas",
      attrs: {
        width: _vm.component.style.position.w,
        height: _vm.component.style.position.h,
      },
    },
    [_vm._v("Your browser does not support the HTML5 canvas tag.")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }