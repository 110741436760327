var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("jtl-pie", {
    ref: "jtlChart",
    attrs: {
      data: _vm.ChartData,
      settings: _vm.ChartSettings,
      height: _vm.ChartHeight,
      width: _vm.ChartWidth,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }