


















import { Component } from 'vue-property-decorator';
import TopoBaseView from '../topo-base-view';

enum ParkingStatusType {
    UNPARK = '空闲',
    PARKED = '占用',
    UNABLE = '未启用',
    BREAK_DOWN = '故障'
}

@Component
export default class ViewParking extends TopoBaseView {
    scale: number = 1;
    debounceResize: Function;
    ParkingStatusType = ParkingStatusType;

    get TextStyle() {
        const textWidth = 100 / this.scale;
        const translate = (1 - this.scale) / 2 * 100 / this.scale;
        return `transform: scale(${this.scale}) translate(-${translate}%, -${translate}%); width: ${textWidth}%`;
    }

    get ParkingStatus(): ParkingStatusType {
        return _.get(this.ComponentData, `${this.component.name}.spaceStatus`);
    }
    get ParkingNo() {
        return _.get(this.ComponentData, `${this.component.name}.spaceNo`);
    }

    mounted() {
        this.debounceResize = _.debounce(this.doResize.bind(this), 200);
        this.doResize();
    }

    onResize() {
        this.debounceResize();
    }

    doResize() {
        this.scale = (this.$refs.parkingWrapper as Element).clientWidth / 50;
    }
}
