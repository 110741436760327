




import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import TopoBaseView from '../topo-base-view';
// import CUSTOM_CHART_OPTIONS from '@topo/assets/data/chart-gauge-custom';
import CUSTOM_CHART_OPTIONS from '@topo/assets/data/chart-gauge-parking2';

@Component
export default class ViewCustomGauge extends TopoBaseView {
    CLONE_CUSTOM_CHART_OPTIONS = _.cloneDeep(CUSTOM_CHART_OPTIONS);
    get ChartOptions() {
        this.CLONE_CUSTOM_CHART_OPTIONS.dataset.source = [[1, this.ComponentData || 0]];
        return this.CLONE_CUSTOM_CHART_OPTIONS;
    }
}
