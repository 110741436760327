




import { Component } from 'vue-property-decorator';
import TopoChartBaseView from './topo-chart-base-view';

@Component
export default class ViewChartPie extends TopoChartBaseView {
}
