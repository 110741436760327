<template>
    <canvas ref="elCanvas" :width="component.style.position.w" :height="component.style.position.h">
        Your browser does not support the HTML5 canvas tag.
    </canvas>
</template>

<script>
import ViewCanvas from './view-canvas';

export default {
    name: 'ViewLineWave',
    extends: ViewCanvas,
    mounted() {
        this.onResize();
    },
    methods: {
        drawLine(ctx, width, height, lineWidth, color) {
            let len = Math.sqrt(width * width + height * height);
            // 起点
            this.ctx.beginPath();
            let x = 0;
            let y = 0;
            let amplitude = 5;
            // 振幅
            let frequency = 5;
            // 频率
            while (x < len) {
                y = amplitude * Math.sin(x / frequency);
                this.ctx.lineTo(x, y);
                x = x + 1;
            }
            ctx.stroke();
        },
        onResize() {
            var w = this.component.style.position.w;
            var h = this.component.style.position.h;
            var el = this.$refs.elCanvas;
            var ctx = el.getContext('2d');
            ctx.clearRect(0, 0, w, h);
            var color = this.getForeColor();
            var lineWidth = this.component.style.lineWidth;
            if (lineWidth === undefined || typeof lineWidth !== 'number') {
                lineWidth = 2;
            }
            this.drawLine(ctx, w, h, lineWidth, color);
        }
    }
};
</script>
