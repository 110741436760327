var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-dropdown",
    {
      attrs: {
        trigger: ["click"],
        "overlay-class-name": "topo-dropdown-overlay",
      },
    },
    [
      _c(
        "a",
        {
          staticClass: "ant-dropdown-link text-ellipsis",
          on: {
            click: function (e) {
              return e.preventDefault()
            },
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.DropdownText) + " "),
          _c("a-icon", { attrs: { type: "caret-down" } }),
        ],
        1
      ),
      _c(
        "a-menu",
        { attrs: { slot: "overlay" }, slot: "overlay" },
        [
          _c("a-menu-item", { key: "0", on: { click: _vm.loginOutClick } }, [
            _vm._v("注销"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }