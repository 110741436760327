









































































import { Component } from 'vue-property-decorator';
import TopoBaseView from '../topo-base-view';
import { UserStoreModule } from '@common-src/store/modules/user';
import { CacheUtil } from '@common-src/model/cache-model';
import TopoRender from '../../topo-render.vue';

@Component
export default class ViewDashboard extends TopoBaseView {
    /**
     * 左侧菜单收起/展开
     */
    private collapsed: boolean = false;
    private selectMenuId: string = null;
    private selectParentMenuId: string = null;

    /**
     * 获取登陆用户的菜单
     */
    get MenuList() {
        return this.ComponentData || [];
    }

    /**
     * 获取项目名
     */
    get ProjectName(): string {
        // return AppStoreModule.ProjectName;
        return '';
    }
    /**
     * 获取项目Logo
     */
    get ProjectLogo(): string {
        // return AppStoreModule.ProjectLogo;
        return '/topo/szzx/logo.png';
    }

    /**
     * 获取登录的用户名
     */
    get UserName() {
        return UserStoreModule.UserName;
    }

    /**
     * 默认打开的一级菜单项
     */
    get DefaultOpenMenuKey() {
        if (this.collapsed) {
            return [];
        }
        return [this.$route.meta.parentMenu || this.$route.path];
    }

    /**
     * 获取左侧菜单展示/收起状态的图标
     */
    get CollapsedIcon() {
        return this.collapsed ? 'menu-unfold' : 'menu-fold';
    }

    /**
     * 判断菜单是否被选中
     */
    isMenuSelected(menuId: string): boolean {
        return this.selectParentMenuId === menuId || this.selectMenuId === menuId;
    }

    /**
     * 菜单点击事件
     */
    menuClick(menu: any, parentMenu?: any) {
        // if (this.editMode) {
        //     return;
        // }
        this.selectMenuId = menu.id;
        this.selectParentMenuId = _.get(parentMenu, 'id');
        (this.$refs.topoRender as TopoRender).initRender(menu.id);
    }

    /**
     * 退出登录
     */
    loginOutClick() {
        if (this.editMode) {
            return;
        }
        CacheUtil.cleanCache();
        UserStoreModule.SET_USERINFO();
        this.$router.push('/sign/login');
    }
}

