var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-dashboard-component" },
    [
      _c(
        "a-layout",
        [
          _c(
            "a-layout",
            [
              _c(
                "a-layout-header",
                { staticClass: "dashboard-header" },
                [
                  _c(
                    "a-row",
                    [
                      _c("a-col", { attrs: { span: 16 } }, [
                        _c("div", { staticClass: "header-logo" }, [
                          _c("img", {
                            staticStyle: { width: "150px" },
                            attrs: { src: _vm.ProjectLogo },
                          }),
                          _c("span", { staticClass: "header-title" }, [
                            _vm._v(_vm._s(_vm.ProjectName)),
                          ]),
                        ]),
                      ]),
                      _c("a-col", [
                        _c(
                          "div",
                          { staticClass: "username-menu text-right" },
                          [
                            _c("jtl-icon", {
                              staticClass: "user-header",
                              attrs: { type: "person" },
                            }),
                            _c(
                              "a-dropdown",
                              { attrs: { trigger: ["click"] } },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "ant-dropdown-link username-menu-email text-ellipsis",
                                    attrs: { title: _vm.UserName },
                                    on: {
                                      click: function (e) {
                                        return e.preventDefault()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" " + _vm._s(_vm.UserName) + " "),
                                    _c("a-icon", { attrs: { type: "down" } }),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-menu",
                                  {
                                    attrs: { slot: "overlay" },
                                    slot: "overlay",
                                  },
                                  [
                                    _c(
                                      "a-menu-item",
                                      { key: "0" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: "/dashboard/person-center",
                                            },
                                          },
                                          [_vm._v("个人中心")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("a-menu-divider"),
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "1",
                                        on: { click: _vm.loginOutClick },
                                      },
                                      [_vm._v("退出")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-layout",
                { staticClass: "dashboard-content" },
                [
                  _c(
                    "a-layout-sider",
                    {
                      staticClass: "dashboard-sider",
                      attrs: { trigger: null, collapsible: "" },
                      model: {
                        value: _vm.collapsed,
                        callback: function ($$v) {
                          _vm.collapsed = $$v
                        },
                        expression: "collapsed",
                      },
                    },
                    [
                      _c(
                        "a-menu",
                        {
                          attrs: {
                            theme: "dark",
                            mode: "inline",
                            "default-open-keys": _vm.DefaultOpenMenuKey,
                          },
                        },
                        [
                          _vm._l(_vm.MenuList, function (menu, index) {
                            return [
                              menu.subMenus && menu.subMenus.length > 0
                                ? _c(
                                    "a-sub-menu",
                                    {
                                      key: menu.id,
                                      class: [
                                        "ant-sub-menu-item-wrapper",
                                        {
                                          "ant-sub-menu-item-wrapper__selected":
                                            _vm.isMenuSelected(menu.id),
                                        },
                                      ],
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "title" },
                                          slot: "title",
                                        },
                                        [
                                          _c("a-icon", {
                                            attrs: { type: menu.icon },
                                          }),
                                          _c("span", [
                                            _vm._v(_vm._s(menu.name)),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._l(menu.subMenus, function (subMenu) {
                                        return _c(
                                          "a-menu-item",
                                          {
                                            key: subMenu.id,
                                            class: [
                                              {
                                                "ant-menu-sub-item-selected":
                                                  _vm.isMenuSelected(
                                                    subMenu.id
                                                  ),
                                              },
                                            ],
                                            on: {
                                              click: function ($event) {
                                                return _vm.menuClick(
                                                  subMenu,
                                                  menu
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(subMenu.name)),
                                            ]),
                                          ]
                                        )
                                      }),
                                    ],
                                    2
                                  )
                                : _c(
                                    "a-menu-item",
                                    {
                                      key: menu.id,
                                      class: [
                                        "ant-menu-item-wrapper",
                                        {
                                          "ant-menu-item-selected ant-menu-item-wrapper__selected":
                                            _vm.isMenuSelected(menu.id),
                                        },
                                      ],
                                      on: {
                                        click: function ($event) {
                                          return _vm.menuClick(menu)
                                        },
                                      },
                                    },
                                    [
                                      _c("a-icon", {
                                        attrs: { type: menu.icon },
                                      }),
                                      _c("span", [_vm._v(_vm._s(menu.name))]),
                                    ],
                                    1
                                  ),
                              _c("a-menu-divider", { key: index }),
                            ]
                          }),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "collapsed-wrapper text-center",
                          on: {
                            click: function ($event) {
                              _vm.collapsed = !_vm.collapsed
                            },
                          },
                        },
                        [_c("a-icon", { attrs: { type: _vm.CollapsedIcon } })],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-layout-content",
                    { staticClass: "dashboard-view" },
                    [
                      _c("TopoRender", {
                        ref: "topoRender",
                        attrs: { "edit-mode": _vm.editMode },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }