var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "topo-panel view-chart-panel",
        { "topo-panel-dragover": _vm.IdDragOver },
        { "topo-panel-editor": _vm.editMode },
      ],
      on: {
        dragenter: function ($event) {
          $event.stopPropagation()
          return _vm.dragenter.apply(null, arguments)
        },
        dragleave: function ($event) {
          $event.stopPropagation()
          return _vm.dragleave.apply(null, arguments)
        },
        drop: _vm.drop,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "chart-panel-header",
          style: { textAlign: _vm.TextAlign },
        },
        [
          _c("span", { staticClass: "chart-panel-title" }, [
            _vm._v(" " + _vm._s(_vm.ChartPanelTitle) + " "),
          ]),
          _c("span", { staticClass: "chart-panel-desc" }, [
            _vm._v(_vm._s(_vm.ChartPanelDesc)),
          ]),
          _vm._m(0),
        ]
      ),
      _c("div", { staticClass: "chart-panel-content" }, [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "chart-panel-bottom" }, [
      _c("div", { staticClass: "chart-panel-dot chart-panel-dot-left" }),
      _c("div", { staticClass: "chart-panel-line" }),
      _c("div", { staticClass: "chart-panel-dot chart-panel-dot-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }