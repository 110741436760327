var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-image" },
    [
      _vm.editMode
        ? [
            _vm.ImageURL !== ""
              ? [
                  _c("img", {
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: { src: _vm.ImageURL },
                    on: {
                      dragstart: function ($event) {
                        $event.preventDefault()
                      },
                      dragover: function ($event) {
                        $event.preventDefault()
                      },
                      drop: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  }),
                ]
              : _c("a-icon", {
                  attrs: {
                    type: "photo_size_select_actual",
                    size: "120px",
                    color: "light",
                  },
                }),
          ]
        : [
            _c("img", {
              staticStyle: { width: "100%", height: "100%" },
              attrs: { src: _vm.ImageURL },
              on: {
                dragstart: function ($event) {
                  $event.preventDefault()
                },
                dragover: function ($event) {
                  $event.preventDefault()
                },
                drop: function ($event) {
                  $event.preventDefault()
                },
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }