import { Component, Ref } from 'vue-property-decorator';
import TopoBaseView from '../topo-base-view';
import DEFAULT_CHART_DATA from '@topo/assets/data/chart-data.json';

@Component
export default class TopoChartBaseView extends TopoBaseView {
    @Ref()
    jtlChart: any;
    debounceResize: Function;

    get ChartData() {
        return _.get(this.component, 'data.componentData.data');
    }
    get ChartSettings() {
        return _.get(this.component, 'data.componentData.settings');
    }
    get ChartHeight() {
        return _.get(this.component, 'style.position.Height');
    }
    get ChartWidth() {
        return _.get(this.component, 'style.position.Width');
    }
    get ChartColors() {
        return _.get(this.component, 'data.componentData.colors');
    }
    get ChartExtend() {
        return _.get(this.component, 'data.componentData.extend');
    }
    mounted() {
        this.debounceResize = _.debounce(this.doResize.bind(this), 200);
        this.doResize();
    }

    onResize() {
        this.debounceResize();
    }

    doResize() {
        if (this.jtlChart) {
            this.jtlChart.resize();
        }
    }
}
