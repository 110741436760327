






import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import TopoRender from '@topo/components/topo/topo-render.vue';
import { UserStoreModule } from '@common-src/store/modules/user';

@Component({ components: { TopoRender } })
export default class ParkingComponent extends BaseComponent {
    mounted() {
        (this.$refs.topoRender as TopoRender).initRender('szzx_parking_overview_pad');
        setTimeout(() => {
            $topoEventHub.$emit('TOPO_LOGIN_USERNAME_EVENT_HUB', UserStoreModule.UserName);
        }, 1000);
    }
}
