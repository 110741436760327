var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "parking-component" },
    [_c("TopoRender", { ref: "topoRender", attrs: { "edit-mode": false } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }