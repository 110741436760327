var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.pageLoading && _vm.topoPageEntityModel
    ? _c(
        "div",
        { staticClass: "topo-render" },
        [
          _c("TopoRenderComponent", {
            attrs: {
              component: _vm.topoPageEntityModel.component,
              "topo-page-entity-model": _vm.topoPageEntityModel,
              "edit-mode": _vm.editMode,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }