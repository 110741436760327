




import { Component } from 'vue-property-decorator';
import TopoChartBaseView from './topo-chart-base-view';
import CHART_GAUGE_CAR_DASHBOARD from '@topo/assets/data/chart-gauge-car-dashboard';
import DEFAULT_GAUGE_DATA from '@topo/assets/data/chart-gauge.json';

@Component
export default class ViewChartGauge extends TopoChartBaseView {
    // get ChartData() {
    //     return _.get(this.component, 'data.componentData.chartData') || DEFAULT_GAUGE_DATA.chartData;
    // }
    get ChartData() {
        return _.get(this.component, 'data.componentData.chartData') || CHART_GAUGE_CAR_DASHBOARD.chartData;
    }
    get ChartSettings() {
        return _.get(this.component, 'data.componentData.chartSettings') || CHART_GAUGE_CAR_DASHBOARD.chartSettings;
    }
}
