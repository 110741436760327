var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "parkingWrapper",
      staticClass: "view-parking",
      on: { resize: _vm.onResize },
    },
    [
      _c("a-tooltip", [
        _vm.ParkingStatus === _vm.ParkingStatusType.PARKED || _vm.editMode
          ? _c("img", {
              staticClass: "parking-car",
              attrs: { src: "/topo/facility/car.png" },
              on: {
                dragstart: function ($event) {
                  $event.preventDefault()
                },
                dragover: function ($event) {
                  $event.preventDefault()
                },
                drop: function ($event) {
                  $event.preventDefault()
                },
              },
            })
          : _vm.ParkingStatus === _vm.ParkingStatusType.UNABLE
          ? _c("div", { staticClass: "parking-div close-parking" })
          : _vm.ParkingStatus === _vm.ParkingStatusType.UNPARK
          ? _c("div", { staticClass: "parking-div free-parking" })
          : _vm.ParkingStatus === _vm.ParkingStatusType.BREAK_DOWN
          ? _c("div", { staticClass: "parking-div bad-parking" })
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "parking-no",
            style: _vm.TextStyle,
            on: {
              dragstart: function ($event) {
                $event.preventDefault()
              },
              dragover: function ($event) {
                $event.preventDefault()
              },
              drop: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [_vm._v(_vm._s(_vm.ParkingNo))]
        ),
        _c(
          "div",
          {
            staticClass: "text-center",
            attrs: { slot: "title" },
            slot: "title",
          },
          [
            _c("span", { staticClass: "parking-tip-title" }, [
              _vm._v(_vm._s(_vm.ParkingNo)),
            ]),
            _c("br"),
            _c("span", [
              _vm._v("状态: "),
              _c(
                "span",
                {
                  class:
                    "parking-tip-status parking-tip-status-" +
                    _vm.ParkingStatus,
                },
                [_vm._v(_vm._s(_vm.ParkingStatus))]
              ),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }