var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-button",
    { attrs: { type: _vm.Type, size: _vm.Size, block: "" } },
    [_vm._v(_vm._s(_vm.Text))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }