import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';

import ViewPanel from './control/panel/view-panel.vue';
import ViewChartPanel from './control/panel/chart-panel.vue';

import ViewText from './control/view-text.vue';
import ViewParkingText from './control/facility/view-parking-text.vue';
import ViewImage from './control/view-image.vue';

import ViewCircular from './control/canvas/ViewCircular.vue';
import ViewLine from './control/canvas/ViewLine.vue';
import ViewLineArrow from './control/canvas/ViewLineArrow.vue';
import ViewLineWave from './control/canvas/ViewLineWave.vue';
import ViewBizierCurveArrow from './control/canvas/ViewBizierCurveArrow.vue';
import ViewRect from './control/canvas/ViewRect.vue';
import ViewTriangle from './control/canvas/ViewTriangle.vue';

// import ViewSvgImage from './control/svg/ViewSvgImage.vue';
// import ViewSvgStatic from './control/svg/ViewSvgStatic.vue';
import ViewDashed from './control/canvas/ViewDashed.vue';

import ViewDashboard from './control/dashboard/view-dashboard.vue';
import ViewParking from './control/facility/view-parking.vue';

import ViewButton from './control/antd/view-button.vue';
import ViewDropdown from './control/antd/view-dropdown.vue';

import ViewChart from './control/chart/view-chart.vue';
import ViewChartHistogram from './control/chart/view-histogram.vue';
import ViewChartBar from './control/chart/view-bar.vue';
import ViewChartLine from './control/chart/view-line.vue';
import ViewChartPie from './control/chart/view-pie.vue';
import ViewChartGauge from './control/chart/view-gauge.vue';
import ViewChartCustomGauge from './control/chart/view-custom-gauge.vue';

@Component({
    components: {
        ViewPanel,
        ViewChartPanel,
        ViewText,
        ViewParkingText,
        ViewImage,
        ViewCircular,
        ViewLine,
        ViewLineArrow,
        ViewLineWave,
        ViewBizierCurveArrow,
        ViewRect,
        ViewTriangle,
        ViewDashed,
        ViewDashboard,
        ViewParking,
        ViewButton,
        ViewDropdown,
        ViewChart,
        ViewChartHistogram,
        ViewChartBar,
        ViewChartLine,
        ViewChartPie,
        ViewChartGauge,
        ViewChartCustomGauge
    }
})
export default class TopoControl extends BaseComponent {
}
